import * as ReactDOM from 'react-dom';
import React, { useState, useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodashGroupBy from 'lodash/groupBy';
import lodashOrderBy from 'lodash/orderBy';
import lodashKeyBy from 'lodash/keyBy';
import { useHubConnection } from '../hubConnection';
import * as storeExt from '../store';
import {
    Alert as RawAlert,
    CollapsiblePanel,
    CollapsiblePanelHeader,
    Tabs,
    ProjectTagsFilter,
    TagManager,
    ToolTip,
    CheckboxField,
    QuickAlert,
} from '../components';
import {
    CpqModal,
    NewTagModal,
    ValidateWarningModal,
    ValidatingModal,
    SaveValidationModal,
} from '../modals';
import { useTitle, cpqDataState } from '../utils';
import { TagsTable } from '../components/tagsTable';
import { ProductDetailTabs } from '../components/productDetailTabs';
import { ProjectHeader } from '../components/ProjectHeader';
import { SelectField, Modal } from '../components';
import {
    getItemValidate,
    sameAsValidatedResult,
} from '../components/validateUtils';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const {
    useGetConfigQuery,
    useGetProjectQuery,
    useUpdateProjectMutation,
    useSaveValidationReusltMutation,
    useUploadDrawingsMutation,
    useGetDrawingsQuery,
    useDeleteDrawingMutation,
    useUpdateDrawingMutation,
    useCreateOverlayMarkerMutation,
    useDeleteOverlayMarkerMutation,
    mainActions,
    documentsApi,
    mainSelectors,
} = storeExt;

const Drawings = (props) => {
    const { projectId } = props;

    const { data: project } = useGetProjectQuery(projectId);
    const { data: drawings } = useGetDrawingsQuery(projectId);
    const selectedItemId = useSelector(mainSelectors.getSelectedItemId);

    const [showEditNameModal, setShowEditNameModal] = React.useState(false);
    const [selectedDrawingId, setSelectedDrawingId] = React.useState('');
    const [leafletMap, setLeafletMap] = React.useState(null);
    const [drawingMarkerEls, setDrawingMarkerEls] = React.useState([]);

    const mapDivRef = React.useRef(null);
    const fileInputRef = React.useRef(null);

    const dispatch = useDispatch();

    const [uploadDrawings, uploadDrawingsResult] = useUploadDrawingsMutation();
    const [deleteDrawing, deleteDrawingResult] = useDeleteDrawingMutation();
    const [updateDrawing, updateDrawingResult] = useUpdateDrawingMutation();
    const [createOverlayMarker, createOverlayMarkerResult] =
        useCreateOverlayMarkerMutation();

    const [deleteOverlayMarker, deleteOverlayMarkerResult] =
        useDeleteOverlayMarkerMutation();

    const drawingsMap = React.useMemo(
        () => (drawings ? lodashKeyBy(drawings, 'id') : null),
        [drawings],
    );

    const selectedTag = React.useMemo(() => {
        return project?.tags?.find((x) =>
            x.items.map((y) => y.id).includes(selectedItemId),
        );
    }, [project, selectedItemId]);

    const firstItemIdForTagId = React.useCallback(
        (tagId) => project?.tags?.find((x) => x.id === tagId)?.items?.[0]?.id,
        [project],
    );

    const selectedDrawing = React.useMemo(
        () =>
            selectedDrawingId.length && drawingsMap
                ? drawingsMap[selectedDrawingId]
                : null,
        [drawingsMap, selectedDrawingId],
    );

    const handleMapClick = (e) => {
        if (selectedTag) {
            const { lat, lng } = e.latlng;

            const yPos = 100 - (lat / selectedDrawing.height) * 100;
            const xPos = (lng / selectedDrawing.width) * 100;
            createOverlayMarker({
                projectId: projectId,
                drawingId: selectedDrawing.id,
                body: {
                    tagId: selectedTag.id,
                    positionX: xPos,
                    positionY: yPos,
                },
            }).unwrap();
        } else {
            dispatch(
                mainActions.addAlert({
                    kind: 'info',
                    msg: 'Select a tag item to place an overlay marker',
                }),
            );
        }
    };

    React.useEffect(() => {
        if (!leafletMap) return;

        leafletMap.on('click', (e) => {
            if (selectedTag) {
                const { lat, lng } = e.latlng;

                const yPos = 100 - (lat / selectedDrawing.height) * 100;
                const xPos = (lng / selectedDrawing.width) * 100;
                createOverlayMarker({
                    projectId: projectId,
                    drawingId: selectedDrawing.id,
                    body: {
                        tagId: selectedTag.id,
                        positionX: xPos.toString(),
                        positionY: yPos.toString(),
                    },
                }).unwrap();
            } else {
                dispatch(
                    mainActions.addAlert({
                        kind: 'info',
                        msg: 'Select a tag item to place an overlay marker',
                    }),
                );
            }
        });

        return () => {
            leafletMap.off('click');
        };
    }, [leafletMap, projectId, selectedTag, selectedDrawing]);

    React.useEffect(() => {
        if (!selectedDrawing || !projectId) return;

        const width = selectedDrawing.width;
        const height = selectedDrawing.height;
        const imageBounds = [
            [0, 0],
            [height, width],
        ];

        const map = L.map(mapDivRef.current, {
            crs: L.CRS.Simple,
            zoomSnap: 0.1,
            minZoom: -3,
            maxZoom: 3,
            maxBounds: L.latLngBounds(L.latLng(0, 0), L.latLng(height, width)),
        });

        L.DomUtil.addClass(map.getContainer(), '!cursor-pointer');

        // var popup = L.popup();

        // console.log({ popup })

        map.fitBounds(imageBounds);

        const markerGroup = L.layerGroup().addTo(map);

        let dmes = [];
        let observers = [];

        for (const overlayMarker of selectedDrawing.overlayMarkers) {
            const yPos =
                ((100 - Number.parseFloat(overlayMarker.positionY)) / 100) *
                height;
            const xPos =
                (Number.parseFloat(overlayMarker.positionX) / 100) * width;
            const marker = L.marker([yPos, xPos], {
                icon: L.divIcon({ className: 'hidden' }),
            }).addTo(markerGroup);

            const markerHtml = (
                <div
                    className="flex items-center justify-between"
                    key={overlayMarker.id}
                >
                    <div
                        className="flex items-center justify-between"
                        onClick={() => {
                            dispatch(
                                mainActions.setSelectedItemId(
                                    firstItemIdForTagId(overlayMarker.tagId),
                                ),
                            );
                        }}
                    >
                        <FontAwesomeIcon
                            className="mr-4"
                            icon="fa-solid fa-tags"
                        />
                        <div className="text-ellipsis">
                            {
                                project?.tags?.find(
                                    (y) => y.id === overlayMarker.tagId,
                                )?.value
                            }
                        </div>
                    </div>
                    <div className="border-l border-white ml-4 pl-4">
                        <FontAwesomeIcon
                            icon="fa-solid fa-xmark"
                            onClick={() => {
                                deleteOverlayMarker({
                                    projectId: projectId,
                                    drawingId: selectedDrawing.id,
                                    overlayMarkerId: overlayMarker.id,
                                }).unwrap();
                            }}
                        />
                    </div>
                </div>
            );

            // console.log({ m1: popup.getContainer() })

            const popupContent = document.createElement('div');

            const popup = L.popup({ className: 'local-popup' }).setContent(
                popupContent,
            );

            marker
                .bindPopup(popup, {
                    closeButton: false,
                    autoClose: false,
                    closeOnClick: false,
                    closeOnEscapeKey: false,
                })
                .openPopup();

            const observer = new MutationObserver((m, o) => {
                popup.update();
            });

            observer.observe(popupContent, { childList: true });
            observers.push(observer);

            dmes.push(ReactDOM.createPortal(markerHtml, popupContent));
        }

        setDrawingMarkerEls(dmes);

        // map.
        // var marker = L.marker([200, 200]).addTo(map);

        // marker.bindPopup("FOO | <strong>BAR</strong>").openPopup();

        L.imageOverlay(
            `/api/projects/${projectId}/drawings/${selectedDrawing.id}`,
            imageBounds,
        ).addTo(map);

        setLeafletMap(map);

        return () => {
            observers.forEach((x) => x.disconnect());
            map.remove();
            setDrawingMarkerEls([]);
        };
    }, [
        selectedDrawing,
        projectId,
        project?.tags,
        setLeafletMap,
        setDrawingMarkerEls,
    ]);

    // React.useEffect(() => {
    //     if (!mapMarkerGroup.current || !selectedDrawing?.overlayMarkers) return;

    //     mapMarkerGroup.current.clearLayers();

    //     for (const marker in selectedDrawing.overlayMarkers)
    //     {
    //         const marker = L.marker([200, 200]).addTo(map);
    //     }

    // }, [mapMarkerGroup.current, selectedDrawing?.overlayMarkers]);

    const orderedDrawingOptions = React.useMemo(
        () => [
            { value: '', name: '' },
            ...((drawings && lodashOrderBy(drawings, 'sequenceId'))?.map(
                (x, idx) => ({
                    value: x.id,
                    name: x.label,
                }),
            ) ?? []),
        ],
        [drawings],
    );

    const nextDrawingId = React.useMemo(() => {
        if (!orderedDrawingOptions) return null;

        const idx = orderedDrawingOptions.findIndex(
            (x) => x.value === selectedDrawingId,
        );
        return orderedDrawingOptions?.[idx + 1]?.value ?? null;
    }, [selectedDrawingId, orderedDrawingOptions]);

    const prevDrawingId = React.useMemo(() => {
        if (!orderedDrawingOptions) return null;

        const idx = orderedDrawingOptions.findIndex(
            (x) => x.value === selectedDrawingId,
        );
        return orderedDrawingOptions?.[idx - 1]?.value ?? null;
    }, [selectedDrawingId, orderedDrawingOptions]);

    const handleFileChange = (e) => {
        e.preventDefault();

        const files = e.target.files;

        if (files.length > 0) {
            const form = new FormData();

            for (let file of files) {
                form.append('files', file);
            }

            uploadDrawings({ projectId: projectId, body: form }).unwrap();
        }
    };

    const getPositions = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        return { x: x.toString(), y: y.toString() };
    };

    return (
        <div className="bg-white border border-black-20 p-4">
            <input
                ref={fileInputRef}
                hidden
                type="file"
                onChange={handleFileChange}
            />
            <div className="flex items-center justify-end mb-4">
                <p className="mr-auto">Select drawing</p>
                <FontAwesomeIcon
                    className="ml-4 cursor-pointer"
                    onClick={() => fileInputRef.current.click()}
                    icon="fa-solid fa-arrow-up-from-bracket"
                />
                <FontAwesomeIcon
                    className="ml-4 cursor-pointer"
                    onClick={() => {
                        if (
                            selectedDrawing &&
                            window.confirm('Are you sure?')
                        ) {
                            deleteDrawing({
                                projectId: projectId,
                                id: selectedDrawing.id,
                            }).unwrap();
                        }
                    }}
                    icon="fa-solid fa-trash-can"
                />
                <FontAwesomeIcon
                    className="ml-4 cursor-pointer"
                    onClick={() =>
                        selectedDrawing && setShowEditNameModal(true)
                    }
                    icon="fa-solid fa-pen-to-square"
                />
                <FontAwesomeIcon
                    className="ml-4 cursor-pointer"
                    onClick={() =>
                        prevDrawingId && setSelectedDrawingId(prevDrawingId)
                    }
                    icon="fa-solid fa-chevron-left"
                />
                <FontAwesomeIcon
                    className="ml-4 cursor-pointer"
                    onClick={() =>
                        nextDrawingId && setSelectedDrawingId(nextDrawingId)
                    }
                    // onClick={() => fileInputRef.current.click()}
                    icon="fa-solid fa-chevron-right"
                />
            </div>
            <div className="mb-4">
                <SelectField
                    value={selectedDrawingId}
                    onChange={(option) => setSelectedDrawingId(option.value)}
                    options={orderedDrawingOptions}
                />
            </div>
            <div>
                <div
                    ref={mapDivRef}
                    style={{ height: '600px' }}
                ></div>
            </div>
            {/* <div>
                {selectedDrawing ? (
                    <div className="relative">
                        <img
                            className="max-w-full h-auto"
                            onClick={(e) => {
                                if (selectedTag) {
                                    const { x, y } = getPositions(e);
                                    createOverlayMarker({
                                        projectId: projectId,
                                        drawingId: selectedDrawing.id,
                                        body: {
                                            tagId: selectedTag.id,
                                            positionX: x,
                                            positionY: y,
                                        },
                                    }).unwrap();
                                } else {
                                    dispatch(
                                        mainActions.addAlert({
                                            kind: 'info',
                                            msg: 'Select a tag item to place an overlay marker',
                                        }),
                                    );
                                }
                            }}
                            src={`/api/projects/${projectId}/drawings/${selectedDrawing.id}`}
                        />
                        {selectedDrawing.overlayMarkers.map((x) => (
                            <div
                                key={x.id}
                                style={{
                                    left: `${x.positionX}%`,
                                    top: `${x.positionY}%`,
                                }}
                                className="absolute text-white border-white bg-green-dark px-4 py-2 cursor-pointer flex items-center justify-between"
                            >
                                <div
                                    className="flex items-center justify-between"
                                    onClick={() => {
                                        dispatch(
                                            mainActions.setSelectedItemId(
                                                firstItemIdForTagId(x.tagId),
                                            ),
                                        );
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className="mr-4"
                                        icon="fa-solid fa-tags"
                                    />
                                    <div>
                                        {
                                            project?.tags?.find(
                                                (y) => y.id === x.tagId,
                                            )?.value
                                        }
                                    </div>
                                </div>
                                <div className="border-l border-white ml-4 pl-4">
                                    <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        onClick={() => {
                                            deleteOverlayMarker({
                                                projectId: projectId,
                                                drawingId: selectedDrawing.id,
                                                overlayMarkerId: x.id,
                                            }).unwrap();
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div> */}
            <Modal
                fitContent
                show={showEditNameModal}
            >
                <div className="sm:container p-4 min-w-[600px]">
                    <h1>Update drawing</h1>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateDrawing({
                                projectId: projectId,
                                id: selectedDrawing?.id,
                                label: e.target.label.value,
                            })
                                .unwrap()
                                .then(() => setShowEditNameModal(false));
                        }}
                    >
                        <div className="pb-4">
                            <div className="swegon-ui-text-input-wrapper">
                                <input
                                    type="text"
                                    name="label"
                                    defaultValue={selectedDrawing?.label}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="swegon-like-button outlined mr-4 !px-4 amber"
                                type="button"
                                onClick={() => setShowEditNameModal(false)}
                            >
                                Close
                            </button>
                            <button
                                className="swegon-like-button contained !px-4"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            {drawingMarkerEls}
        </div>
    );
};

const Tags = (props) => {
    const { tags: propsTags, projectId } = props;

    const tags = lodashOrderBy(propsTags ?? [], 'sequenceId');

    return (
        <CollapsiblePanel
            defaultOpen
            bordered
            header={CollapsiblePanelHeader}
            title={`Tags (${tags.length})`}
            showWarning={tags.some((x) =>
                x.items.some((y) => y.needValidate || y.needAction),
            )}
        >
            <div className="mb-2">
                <TagsTable
                    tags={tags}
                    projectId={projectId}
                />
            </div>
        </CollapsiblePanel>
    );
};

const EquipmentType = (props) => {
    const { tags: propsTags, projectId } = props;

    const groups = lodashGroupBy(lodashOrderBy(propsTags ?? [], 'sequenceId'), 'equipmentType');

    return (
        <>
            {Object.entries(groups).map(([name, tags]) => (
                <CollapsiblePanel
                    bordered
                    header={CollapsiblePanelHeader}
                    title={`${name} (${tags.length})`}
                    key={name}
                    showWarning={tags.some((x) =>
                        x.items.some((y) => y.needValidate || y.needAction),
                    )}
                >
                    <div className="mb-2">
                        <TagsTable
                            tags={tags}
                            projectId={projectId}
                        />
                    </div>
                </CollapsiblePanel>
            ))}
        </>
    );
};

const Room = (props) => {
    const { tags: propsTags, projectId } = props;

    const groups = lodashGroupBy(lodashOrderBy(propsTags ?? [], 'sequenceId'), 'room');

    return (
        <>
            {Object.entries(groups).map(([name, tags]) => (
                <CollapsiblePanel
                    bordered
                    header={CollapsiblePanelHeader}
                    title={`${name} (${tags.length})`}
                    key={name}
                    showWarning={tags.some((x) =>
                        x.items.some((y) => y.needValidate || y.needAction),
                    )}
                >
                    <div className="mb-2">
                        <TagsTable
                            tags={tags}
                            projectId={projectId}
                        />
                    </div>
                </CollapsiblePanel>
            ))}
        </>
    );
};

const TabComponent = {
    tags: Tags,
    equipment: EquipmentType,
    room: Room,
};

const Project = () => {
    const dispatch = useDispatch();

    const [cpqData, setCpqData] = cpqDataState.useStateContext();

    const { projectId } = useParams();
    const { data: config } = useGetConfigQuery();
    const { data: project } = useGetProjectQuery(projectId);
    const [updateProject, updateProjectResult] = useUpdateProjectMutation();

    const [newTagModalOpen, setNewTagModalOpen] = React.useState(false);
    const [isUpdatingProjectName, setIsUpdatingProjectName] =
        React.useState(false);
    const [updatedProjectName, setUpdatedProjectName] = React.useState('');

    //tags to be seen by the user based on filter
    const [filteredTags, setFilteredTags] = useState(project?.tags);

    // const [cpqState, setCpqState] = React.useState({ showModal: false });
    // const [selectedProdState, setSelectedProdState] = React.useState({
    //     isSelected: false,
    //     prodId: null,
    // });
    const [showWarnValidateModal, setShowWarnValidateModal] =
        React.useState(false);
    const [showValidatingModal, setShowValidatingModal] = React.useState(false);
    const [itemsAlreadyValidated, setItemsAlreadyValidated] = React.useState(0);
    const [showSaveValidationModal, setShowSaveValidationModal] =
        React.useState(false);
    const [validationData, setValidationData] = React.useState({});
    // const [showWaitingModal, setShowWaitingModal] = React.useState(false);
    // const [waitingModalMsg, setWaitingModalMsg] = React.useState({
    //     isSimple: true,
    //     msg: null,
    // });

    useTitle(React.useMemo(() => project?.name ?? 'Project', [project]));

    React.useEffect(() => {
        localStorage.setItem('Validating', '0');
        localStorage.setItem('ToValidate', '');
    }, []);

    React.useEffect(() => {
        if (!project) return;
        setUpdatedProjectName(project.name);
    }, [project]);

    const hubConnection = useHubConnection();

    React.useEffect(() => {
        const handleReceiveItemDocMessage = (jobId, itemId) => {
            dispatch(
                documentsApi.util.prefetch('getProductDocuments', itemId,
                    { force: true, }),
            );
            dispatch(
                documentsApi.util.prefetch('getProductHasJobInProgress',
                    itemId, { force: true, }),
            );
        };
        const sendItemDocAck = (connection, jobId, itemId, isWorkflowJob) => {
            connection.invoke('ItemDocAcknowledge', jobId, itemId, isWorkflowJob);
        };
        const handleReceiveQuoteDocMessage = (jobId, quoteId) => {
            // console.log("In project....");
            dispatch(
                documentsApi.util.prefetch('getQuoteDocuments', quoteId,
                    { force: true, }),
            );
            dispatch(
                documentsApi.util.prefetch('getQuoteHasJobInProgress', quoteId,
                    { force: true, }),
            );
        };
        const sendQuoteDocAck = (connection, jobId, quoteId) => {
            connection.invoke('QuoteDocAcknowledge', jobId, quoteId);
        };

        if (hubConnection && config?.signalR) {
            hubConnection.then((connection) => {
                // console.log("Subscribing in project.....")
                connection.on(
                    config.signalR.ItemDoc ?? 'ItemMessage',
                    (jobId, itemId, isWorkflowJob) => {
                        handleReceiveItemDocMessage(jobId, itemId);
                        sendItemDocAck(connection, jobId, itemId, isWorkflowJob);
                    },
                );
                connection.on(
                    config.signalR.QuoteDoc ?? 'QuoteMessage',
                    (jobId, quoteId) => {
                        handleReceiveQuoteDocMessage(jobId, quoteId);
                        sendQuoteDocAck(connection, jobId, quoteId);
                    },
                );
            });
        }

        // Clean up
        return () => {
            if (hubConnection && config?.signalR) {
                hubConnection.then((connection) => {
                    // console.log("Unsubscribing in project.....");
                    connection.off(
                        config.signalR.ItemDoc ?? 'ItemMessage',
                        handleReceiveItemDocMessage,
                    );
                    connection.off(
                        config.signalR.QuoteDoc ?? 'QuoteMessage',
                        handleReceiveQuoteDocMessage,
                    );
                });
            }
        };
    }, [hubConnection, config, dispatch]);

    const itemsNeedChecking = React.useMemo(() => {
        if (!project) return [];
        if (project.tags.length === 0) return [];
        const result = [];
        const { tags } = project;
        localStorage.setItem('ToValidate', '');
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].items.length > 0) {
                const { items } = tags[i];
                const targets = items.filter((x) => x.needValidate);
                targets.forEach((x) => {
                    result.push(x);
                });
            }
        }
        let toValidate = localStorage.getItem('ToValidate');
        for (let i = 0; i < result.length; i++) {
            localStorage.setItem(
                'ToValidate',
                toValidate === ''
                    ? result[i].id + ','
                    : toValidate + result[i].id + ',',
            );
            toValidate = localStorage.getItem('ToValidate');
        }
        return result;
    }, [project]);

    React.useEffect(() => {
        if (itemsNeedChecking.length > 0) {
            setShowWarnValidateModal(true);
        }
    }, [itemsNeedChecking]);

    const onSiteInfoConfiguratorLoaded = React.useCallback(
        (embedConfigurator) => {
            if (project?.siteInfoConfiguratorJson) {
                embedConfigurator.setConfiguredProduct(
                    JSON.parse(project.siteInfoConfiguratorJson),
                );
            }
        },
        [project],
    );

    const handleNameUpdate = async () => {
        const name = updatedProjectName;
        dispatch(
            mainActions.showWaitingModal({
                isSimple: true,
                msg: 'Saving new name to project and all its items',
            }),
        );
        try {
            await updateProject({ id: projectId, name }).unwrap();
            setIsUpdatingProjectName(false);
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const handleSiteInfoUpdate = async (value) => {
        const siteInfoStr = value?.fields?.find(
            (x) => x.name === 'fSiteConditionsShortDescription',
        )?.stringValue;
        const siteInfo = siteInfoStr ? JSON.parse(siteInfoStr) : undefined;
        setCpqData({ showModal: false });
        dispatch(
            mainActions.showWaitingModal({
                isSimple: true,
                msg: 'Saving site info to project and all items ',
            }),
        );
        try {
            await updateProject({
                id: projectId,
                siteInfo,
                siteInfoConfiguratorJson: JSON.stringify(value),
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const validateAllItems = useCallback(
        async (data, onClose) => {
            let toValidate = localStorage.getItem('ToValidate');
            const validating = localStorage.getItem('Validating');
            let validateCount = 0;
            if (validating !== '0') return;
            const markResolve = [];
            const markError = [];
            while (toValidate !== '') {
                const itemIds = toValidate.split(',');
                localStorage.setItem('Validating', itemIds[0]);

                let itemToValidate = data.find(
                    (x) => x.id === itemIds[0],
                );
                setItemsAlreadyValidated(validateCount + 1);
                validateCount++;

                var result = await dispatch(
                    getItemValidate(project, itemToValidate),
                );
                if (result) {
                    const prodConfig = JSON.parse(
                        itemToValidate.configuratorJson,
                    ).configuredProduct;
                    console.log("Product Config:");
                    console.log(prodConfig);
                    console.log(JSON.stringify(prodConfig));
                    console.log("Result:");
                    console.log(result);
                    console.log(JSON.stringify(result));
                    if (sameAsValidatedResult(prodConfig, result)) {
                        markResolve.push({
                            itemId: itemToValidate.id,
                            tagId: itemToValidate.tagId,
                        });
                    } else {
                        markError.push({
                            itemId: itemToValidate.id,
                            tagId: itemToValidate.tagId,
                        });
                    }
                }

                const updated = toValidate.replace(itemIds[0] + ',', '');
                localStorage.setItem('ToValidate', updated);
                toValidate = localStorage.getItem('ToValidate');
            }
            localStorage.setItem('Validating', '0');
            onClose();
            setValidationData({
                itemNum: data.length,
                okItems: markResolve,
                errorItems: markError,
            });
            setShowSaveValidationModal(true);
        },
        [setItemsAlreadyValidated, project, dispatch],
    );

    return (
        <div className="">
            <ProjectHeader
                parentPath="projects"
                isUpdatingProjectName={isUpdatingProjectName}
                updatedProjectName={updatedProjectName}
                setIsUpdatingProjectName={setIsUpdatingProjectName}
                setUpdatedProjectName={setUpdatedProjectName}
                handleNameUpdate={handleNameUpdate}
                onSiteInfoConfiguratorLoaded={onSiteInfoConfiguratorLoaded}
                handleSiteInfoUpdate={handleSiteInfoUpdate}
            />
            <div className="flex gap-4">
                <div className="w-1/2">
                    <Tabs.Context defaultValue="tags">
                        <div className="flex flex-wrap justify-between mb-4">
                            <Tabs.List>
                                <Tabs.Tab
                                    value="tags"
                                    label="Tags"
                                />
                                <Tabs.Tab
                                    value="equipment"
                                    label="Equipment"
                                />
                                <Tabs.Tab
                                    value="room"
                                    label="Room"
                                />
                            </Tabs.List>
                            <div className="flex flex-wrap justify-end space-x-2">
                                <div
                                    onClick={() => setNewTagModalOpen(true)}
                                    className="bg-green-dark hover:bg-green-primary text-white cursor-pointer p-2 "
                                >
                                    Add Tag <FontAwesomeIcon icon="fa-solid fa-tags" />
                                </div>
                                <div className="bg-green-dark hover:bg-green-primary text-white cursor-pointer p-2">
                                    <ProjectTagsFilter
                                        tags={project?.tags}
                                        setFilteredTags={setFilteredTags}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white border border-black-20 p-4">
                            <Tabs.Panel value="tags">
                                <Tags
                                    tags={filteredTags}
                                    projectId={projectId}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel value="equipment">
                                <EquipmentType
                                    tags={filteredTags}
                                    projectId={projectId}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel value="room">
                                <Room
                                    tags={filteredTags}
                                    projectId={projectId}
                                />
                            </Tabs.Panel>
                        </div>
                    </Tabs.Context>
                </div>
                <div className="w-1/2">
                    <Tabs.Context defaultValue="properties">
                        <div className="mb-4">
                            <Tabs.List>
                                <Tabs.Tab
                                    value="properties"
                                    label="Details"
                                />
                                <Tabs.Tab
                                    value="drawings"
                                    label="Drawing Markups"
                                />
                            </Tabs.List>
                        </div>
                        <Tabs.Panel value="properties">
                            <ProductDetailTabs />
                        </Tabs.Panel>
                        <Tabs.Panel value="drawings">
                            <Drawings projectId={projectId} />
                        </Tabs.Panel>
                    </Tabs.Context>
                </div>
            </div>

            <CpqModal
                configuratorId={cpqData.configuratorId}
                viewerClassNames={
                    cpqData.configuratorId === config?.configurators?.Takeoff &&
                    'max-h-[calc(60vh)]'
                }
                show={cpqData.showModal}
                fullScreen={cpqData.fullScreen}
                onConfiguratorLoaded={cpqData.onConfiguratorLoaded}
                projectInfo={cpqData.projectInfo}
                projectSiteInfoJson={cpqData.projectSiteInfoJson}
                onSubmit={cpqData.onSubmit}
                onClose={() => {
                    setCpqData({ showModal: false });
                }}
            />
            <NewTagModal
                show={newTagModalOpen}
                onClose={() => setNewTagModalOpen(false)}
                projectId={projectId}
                tags={project?.tags}
                equipmentList={config?.options.equipmentList}
            />
            <ValidateWarningModal
                show={showWarnValidateModal}
                onClose={() => setShowWarnValidateModal(false)}
                onValidate={() => {
                    setShowWarnValidateModal(false);
                    setShowValidatingModal(true);
                }}
                items={itemsNeedChecking}
            />
            <ValidatingModal
                show={showValidatingModal}
                onClose={() => setShowValidatingModal(false)}
                onValidateItem={() => {
                    localStorage.setItem('Validating', '0');
                    validateAllItems(itemsNeedChecking, () =>
                        setShowValidatingModal(false),
                    );
                }}
                itemsToValidate={itemsNeedChecking}
                itemsValidated={itemsAlreadyValidated}
            />
            <SaveValidationModal
                show={showSaveValidationModal}
                data={validationData}
                projectId={projectId}
                onClose={() => setShowSaveValidationModal(false)}
            />
        </div>
    );
};

const ProjectWrapped = cpqDataState.withStateContext(Project);

export { ProjectWrapped as Project };
