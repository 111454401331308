import React from 'react';
import { useParams } from 'react-router-dom';
import * as storeExt from '../store';
import { useTitle } from '../utils';
import { ProjectHeader } from '../components/ProjectHeader';
import { ExportDocModal, WaitingModal } from '../modals';
import lodashGroupBy from 'lodash/groupBy';
import lodashFindIndex from 'lodash/findIndex';
import lodashSortBy from 'lodash/sortBy';
import lodashJoin from 'lodash/join';
import lodashUniq from 'lodash/uniq';
import { ChevronButton } from '../components/ChevronButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@swegon-core/ui-components';
import { useHubConnection } from '../hubConnection';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { CheckboxField } from '../components';
import Select from 'react-select';

const {
    useGetProjectQuery,
    useBuildQuoteDocumentMutation,
    useGetConfigQuery,
    useGetQuoteDocumentsQuery,
    useSubmitQuoteDocJobMutation,
    useDeleteQuoteDocumentMutation,
    useSubmitQuoteDocBatchJobMutation,
    documentsApi,
    mainActions,
} = storeExt;

const SortByDocCreatedDateAscending = (a, b) =>
    SortByDocCreatedDate(a, b, true);

const SortByDocCreatedDateDescending = (a, b) =>
    SortByDocCreatedDate(a, b, false);

const SortByDocCreatedDate = (a, b, ascending = true) => {
    if (a.createdAt < b.createdAt) {
        return ascending ? -1 : 1;
    }
    if (a.createdAt > b.createdAt) {
        return ascending ? 1 : -1;
    }
    return 0;
};

const GetMenuDisableValue = (menuEntries, value) => {
    const idx = lodashFindIndex(menuEntries, function(o) { return o.value === value; });
    if (idx >= 0)
    {
        return menuEntries[idx].disabled;
    }
    return true;
};

export const Document = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId);
    const {
        data: documents,
        error,
        isLoading: isDocLoading,
    } = useGetQuoteDocumentsQuery(projectId);
    const { data: config } = useGetConfigQuery();
    const { data: stillprinting } = storeExt.useGetQuoteHasJobInProgressQuery(projectId);
    const [buildDocument, buildDocumentResult] =
        useBuildQuoteDocumentMutation();
    const [submitBuildJob, submitBuildJobResult] =
        useSubmitQuoteDocJobMutation();
    const [submitBatchBuildJob, submitBatchBuildResult] =
        useSubmitQuoteDocBatchJobMutation();
    const [showExportModal, setExportModalOpen] = React.useState(false);
    const [selectedSubmittal, setSelectedSubmittal] = React.useState(true);
    const [selectedDataSheets, setSelectedDataSheets] = React.useState(false);
    const [selectedCalcSummary, setSelectedCalcSummary] = React.useState(false);
    const [selectedShopPackage, setSelectedShopPackage] = React.useState(true);
    const [submittalSelectedTags, setSubmittalSelectedTags] = React.useState([]);
    const [dataSheetSelectedTags, setDataSheetSelectedTags] = React.useState([]);
    const [calcSummarySelectedTags, setCalcSummarySelectedTags] = React.useState([]);
    const [shopPackageSelectedTags, setShopPackageSelectedTags] = React.useState([]);
    // const [showWaitingModal, setShowWaitingModal] = React.useState(false);
    // const [waitingModalMsg, setWaitingModalMsg] = React.useState({
    //     isSimple: true,
    //     msg: null,
    // });
    const defaultSortedTagList = React.useMemo(() => {
        return project?.tags
            .map((tag) => tag.value)
            .reduce(function (acc, curr) {
                if (!acc.includes(curr)) acc.push(`"${curr}"`);
                return acc;
            }, [])
            .sort();
    }, [project?.tags]);

    const needExport = React.useMemo(() => {
        return (
            selectedSubmittal ||
            selectedDataSheets ||
            selectedCalcSummary ||
            selectedShopPackage
        );
    }, [
        selectedSubmittal,
        selectedDataSheets,
        selectedCalcSummary,
        selectedShopPackage,
    ]);
    const quoteDocuments = React.useMemo(
        () => config?.quoteDocuments ?? {},
        [config],
    );
    const selectedOutputTypes = React.useMemo(() => {
        return {
            submittal: selectedSubmittal,
            dataSheet: selectedDataSheets,
            calcSummary: selectedCalcSummary,
            shopPackage: selectedShopPackage,
        };
    }, [
        selectedSubmittal,
        selectedDataSheets,
        selectedCalcSummary,
        selectedShopPackage
    ]);
    const selectedOutputs = React.useMemo(() => {
        const numOutputs = Object.entries(selectedOutputTypes).map(
            ([k, v]) => { return v ? 1 : 0; }
        ).reduce((acc, v) => {
            return acc + v;
        }, 0);
        return numOutputs;
    }, [selectedOutputTypes]);

    useTitle(React.useMemo(() => project?.name ?? 'Project', [project]));

    const hubConnection = useHubConnection();
    React.useEffect(() => {
        const handleReceiveItemDocMessage = (jobId, itemId) => {
            dispatch(
                documentsApi.util.prefetch('getProductDocuments', itemId,
                    { force: true, }),
            );
            dispatch(
                documentsApi.util.prefetch('getProductHasJobInProgress',
                    itemId, { force: true, }),
            );
        };
        const sendItemDocAck = (connection, jobId, itemId, isWorkflowJob) => {
            connection.invoke('ItemDocAcknowledge', jobId, itemId, isWorkflowJob);
        };
        const handleReceiveQuoteDocMessage = (jobId, quoteId) => {
            // console.log("In document, received quote job done message....");
            dispatch(
                documentsApi.util.prefetch('getQuoteDocuments', quoteId,
                    { force: true, }),
            );
            dispatch(
                documentsApi.util.prefetch('getQuoteHasJobInProgress', quoteId,
                    { force: true, }),
            );
        };
        const sendQuoteDocAck = (connection, jobId, quoteId) => {
            connection.invoke('QuoteDocAcknowledge', jobId, quoteId);
        };

        if (hubConnection && config?.signalR) {
            hubConnection.then((connection) => {
                // console.log("Subscribing in document....");
                connection.on(
                    config.signalR.ItemDoc ?? 'ItemMessage',
                    (jobId, itemId, isWorkflowJob) => {
                        handleReceiveItemDocMessage(jobId, itemId);
                        sendItemDocAck(connection, jobId, itemId, isWorkflowJob);
                    },
                );
                connection.on(
                    config.signalR.QuoteDoc ?? 'QuoteMessage',
                    (jobId, quoteId) => {
                        handleReceiveQuoteDocMessage(jobId, quoteId);
                        sendQuoteDocAck(connection, jobId, quoteId);
                    },
                );
            });
        }

        // Clean up
        return () => {
            if (hubConnection && config?.signalR) {
                hubConnection.then((connection) => {
                    // console.log('Unsubscribing in document.....');
                    connection.off(
                        config.signalR.ItemDoc ?? 'ItemMessage',
                        handleReceiveItemDocMessage,
                    );
                    connection.off(
                        config.signalR.QuoteDoc ?? 'QuoteMessage',
                        handleReceiveQuoteDocMessage,
                    );
                });
            }
        };
    }, [hubConnection, config, dispatch]);

    const getCheckedByValue = React.useCallback((value) => {
        let result;
        switch (value) {
            case 'CalculationSummary':
                result = selectedCalcSummary;
                break;
            case 'DataSheet':
                result = selectedDataSheets;
                break;
            case 'ShopPackage':
                result = selectedShopPackage;
                break;
            case 'Submittal':
                result = selectedSubmittal;
                break;
            default:
                result = null;
        }
        return result;
    },[selectedCalcSummary, selectedDataSheets, selectedShopPackage, selectedSubmittal]);

    const exportMenu = React.useMemo(() => {
        if (!config?.exportMenu) return [];
        const temp = lodashSortBy(config?.exportMenu, ['ordinal']);

        const result = temp
            .map((item) => ({
                label: item.label,
                value: item.value,
                description: item.description,
                disabled: GetMenuDisableValue(config?.exportMenu, item.value),
            }));
        return result;
    }, [config?.exportMenu]);

    const sortAndFormatTagList = (tagList) => {
        const result = tagList
            .sort()
            .reduce(function (acc, curr) {
                acc.push(`"${curr}"`);
                return acc;
            }, []);
        return result;
    }

    const exportDocument = async () => {
        if (selectedOutputs === 0) {
            setExportModalOpen(false);
            return;
        }

        const finalSubmittalTags = lodashJoin(submittalSelectedTags.length > 0
            ? lodashUniq(sortAndFormatTagList(submittalSelectedTags))
            : defaultSortedTagList, ',');
        const finalCalcSummaryTags = lodashJoin(calcSummarySelectedTags.length > 0
            ? lodashUniq(sortAndFormatTagList(calcSummarySelectedTags))
            : defaultSortedTagList, ',');
        const finalDataSheetTags = lodashJoin(dataSheetSelectedTags.length > 0
            ? lodashUniq(sortAndFormatTagList(dataSheetSelectedTags))
            : defaultSortedTagList, ',');
        const finalShopPackageTags = lodashJoin(shopPackageSelectedTags.length > 0
            ? lodashUniq(sortAndFormatTagList(shopPackageSelectedTags))
            : defaultSortedTagList, ',');
        
/*        if (selectedOutputs === 1) {
            let printDocType;
            if (selectedSubmittal) {
                printDocType = quoteDocuments.SalesSubmittals;
            } else if (selectedDataSheets) {
                printDocType = quoteDocuments.SalesSubmittals;
            } else if (selectedCalcSummary) {
                printDocType = quoteDocuments.SalesSubmittals;
            } else {
                printDocType = quoteDocuments.ShopPackage;
            }
            dispatch(
                mainActions.showWaitingModal({
                    isSimple: false,
                    msg: "Exporting Project's Sales Submittals...\nPlease wait as it will take a while.",
                }),
            );
            setExportModalOpen(false);
            try { */
                /*
                await buildDocument({
                    id: project.id,
                    docType: quoteDocuments.SalesSubmittals,
                }).unwrap();
                */
/*                await submitBuildJob({
                    id: project.id,
                    docType: printDocType,
                }).unwrap();
            } catch (err) {
                console.log(err);
            } finally {
                dispatch(mainActions.hideWaitingModal());
                dispatch(documentsApi.util.prefetch('getQuoteHasJobInProgress',
                    projectId, { force: true, }));
            }
        } */
        dispatch(
            mainActions.showWaitingModal({
                isSimple: false,
                msg: "Exporting Project's Sales Submittals...\nPlease wait as it will take a while.",
            }),
        );
        setExportModalOpen(false);
        try {
            var requestBody = {
                printSubmittal: selectedSubmittal,
                submittalPrintTags: selectedSubmittal ? `[${finalSubmittalTags}]` : "",
                printDataSheet: selectedDataSheets,
                dataSheetPrintTags: selectedDataSheets ? `[${finalDataSheetTags}]` : "",
                printCalcSummary: selectedCalcSummary,
                calcSummaryPrintTags: selectedCalcSummary ? `[${finalCalcSummaryTags}]` : "",
                printShopPackage: selectedShopPackage,
                shopPackagePrintTags: selectedShopPackage ? `[${finalShopPackageTags}]` : "",
            };
            await submitBatchBuildJob({
                id: project.id,
                req: requestBody,
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
            dispatch(documentsApi.util.prefetch('getQuoteHasJobInProgress',
                projectId, { force: true }));
        }
    };

    const groupedOptions = React.useMemo(() => {
        const equips = project?.tags
            .map((tag) => tag.equipmentType)
            .reduce(function (acc, curr) {
                if (!acc.includes(curr)) acc.push(curr);
                return acc;
            }, [])
            .sort();
        const options = project?.tags
            .map((tag) => ({
                value: tag.id,
                label: tag.value,
                equipmentType: tag.equipmentType,
            }))
            .sort((item) => item.label);
        return equips !== undefined
            ? equips.map((equip) => {
                return {
                    label: equip,
                    options: options.filter(
                        (option) => option.equipmentType === equip,
                    ),
                };
            })
            : null;
    }, [project?.tags]);

    const defaultTagOptions = React.useMemo(() => {
        const options = project?.tags
            .map((tag) => ({
                value: tag.id,
                label: tag.value,
                equipmentType: tag.equipmentType,
            }))
            .sort((item) => item.label);
        return options;
    }, [project?.tags]);

    const onSubmittalTagsChange = (newValue, actionMeta) => {
        let setValue;
        switch (actionMeta.action) {
            case 'clear':
                setValue = [];
                break;
            case 'remove-value':
                const idx = submittalSelectedTags.indexOf(actionMeta.removedValue.label);
                if (idx >= 0) {
                    setValue = submittalSelectedTags.toSpliced(idx, 1);
                }
                break;
            case 'select-option':
                setValue = [ ...submittalSelectedTags, newValue[newValue.length-1].label];
                break;
            default:
                return;
                break;
        }
        setSubmittalSelectedTags(setValue);
    }

    const onShopPackageTagsChange = (newValue, actionMeta) => {
        let setValue;
        switch (actionMeta.action) {
            case 'clear':
                setValue = [];
                break;
            case 'remove-value':
                const idx = shopPackageSelectedTags.indexOf(actionMeta.removedValue.label);
                if (idx >= 0) {
                    setValue = shopPackageSelectedTags.toSpliced(idx, 1);
                }
                break;
            case 'select-option':
                setValue = [ ...shopPackageSelectedTags, newValue[newValue.length-1].label];
                break;
            default:
                return;
                break;
        }
        setShopPackageSelectedTags(setValue);
    }

    const onDataSheetTagsChange = (newValue, actionMeta) => {
        let setValue;
        switch (actionMeta.action) {
            case 'clear':
                setValue = [];
                break;
            case 'remove-value':
                const idx = dataSheetSelectedTags.indexOf(actionMeta.removedValue.label);
                if (idx >= 0) {
                    setValue = dataSheetSelectedTags.toSpliced(idx, 1);
                }
                break;
            case 'select-option':
                setValue = [ ...dataSheetSelectedTags, newValue[newValue.length-1].label];
                break;
            default:
                return;
                break;
        }
        setDataSheetSelectedTags(setValue);
    }

    const onCalcSummaryTagsChange = (newValue, actionMeta) => {
        let setValue;
        switch (actionMeta.action) {
            case 'clear':
                setValue = [];
                break;
            case 'remove-value':
                const idx = calcSummarySelectedTags.indexOf(actionMeta.removedValue.label);
                if (idx >= 0) {
                    setValue = calcSummarySelectedTags.toSpliced(idx, 1);
                }
                break;
            case 'select-option':
                setValue = [ ...calcSummarySelectedTags, newValue[newValue.length-1].label];
                break;
            default:
                return;
                break;
        }
        setCalcSummarySelectedTags(setValue);
    }

    const content = (
        <React.Fragment>
            <h1>Export</h1>
            <div className="my-6 px-4 border border-black-20">
                {exportMenu.map((item, index) => {
                    const css = index === 0
                        ? classNames(
                            'pb-4 pt-2',
                            item.disabled ? 'text-black-20' : '',
                        )
                        : classNames(
                            'py-4 border-t border-black-20',
                            item.disabled? 'test-black-20': '',
                        );
                    var corrOnChange = null;
                    switch (item.value) {
                        case 'Submittal':
                            corrOnChange = onSubmittalTagsChange;
                            break;
                        case 'DataSheet':
                            corrOnChange = onDataSheetTagsChange;
                            break;
                        case 'CalculationSummary':
                            corrOnChange = onCalcSummaryTagsChange;
                            break;
                        case 'ShopPackage':
                            corrOnChange = onShopPackageTagsChange;
                            break;
                        default:
                            break;
                    }

                    return (
                        <div key={index}>
                            <div
                                className={css}
                                key={item.value}
                            >
                                <div className="m-1">
                                    <CheckboxField
                                        value={item.value}
                                        label={item.label}
                                        checked={getCheckedByValue(item.value)}
                                        onChange={() => {
                                            if (item.value === 'Submittal') {
                                                setSelectedSubmittal(!selectedSubmittal);
                                            }
                                            if (item.value === 'DataSheet') {
                                                setSelectedDataSheets(!selectedDataSheets);
                                            }
                                            if (item.value === 'CalculationSummary') {
                                                setSelectedCalcSummary(!selectedCalcSummary);
                                            }
                                            if (item.value === 'ShopPackage') {
                                                setSelectedShopPackage(!selectedShopPackage);
                                            }
                                        }}
                                        disabled={item.disabled}
                                        labelClassName={classNames(
                                            'font-bold',
                                            item.disabled
                                                ? 'text-black-20'
                                                : 'text-green-dark',
                                        )}
                                    />
                                </div>
                                {item.description}
                            </div>
                            <div className="mt-4 mb-4">
                                {/*
                                <Select
                                    placeholder='All'
                                    options={groupedOptions}
                                    isDisabled={item.disabled}
                                />
                                */}
                                <Select
                                    placeholder='All tags'
                                    isMulti
                                    options={groupedOptions}
                                    isDisabled={item.disabled}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name={item.value + "-tags"}
                                    onChange={corrOnChange}
                                    menuPlacement={index !== (exportMenu.length - 1) ? "bottom" : "top"}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );

    return (
        <div className="">
            <ProjectHeader
                parentPath="documents"
                setExportState={setExportModalOpen}
            />
            <DocumentContent
                project={project}
                isLoading={isDocLoading}
                documents={documents}
                hasJobInProgress={stillprinting}
            />
            <ExportDocModal
                show={showExportModal}
                onClose={() => setExportModalOpen(false)}
                onExport={exportDocument}
                needExport={needExport}
            >
                {content}
            </ExportDocModal>
        </div> 
    );
};

const DocumentContent = (props) => {
    const { project, isLoading, documents, hasJobInProgress } = props;
    const hasDocumetns = documents?.length > 0;

    return (
        <div className="bg-white border border-black-20 px-4 py-4">
            <div className="border border-black-20 px-4 py-4 divide-y divide-black-20">
                <div className="text-2xl font-bold mb-2">Documents</div>
                <table className="tags-table">
                    <DocumentHeader />
                    {isLoading ? (
                        <tbody>
                            <tr>
                                <td className="pl-1 pr-1">&nbsp;</td>
                                <td colSpan="4">Loading documents...</td>
                            </tr>
                            <tr>
                                <td className="pl-1 pr-1">&nbsp;</td>
                                <td colSpan="4">
                                    <Spinner color="green" />
                                </td>
                            </tr>
                        </tbody>
                    ) : hasDocumetns ? (
                        <DocumentBody
                            project={project}
                            documents={documents}
                            hasJobInProgress={hasJobInProgress}
                        />
                    ) : (
                        <tbody>
                            { hasJobInProgress
                                ? (
                                    <tr
                                        key={'Spinning'}
                                    >
                                        <td align="center" colSpan="4">
                                            <Spinner color='green' />
                                        </td>
                                    </tr>)
                                : null
                            }
                            <tr>
                                <td className="pl-1 pr-1">&nbsp;</td>
                                <td colSpan="4">
                                    Currently no documents to show here
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

const DocumentHeader = () => {
    return (
        <thead className="text-black-30 border-t-2 border-b-2 border-black-20">
            <tr>
                <th className="pl-1 pr-1 w-1">{'\xa0'}</th>
                <th className="pl-1 pr-4 w-3/5">FileName</th>
                <th className="px-4 w-1 w-1/5">Revison</th>
                <th className="pl-4 pr-1">Date Created</th>
                <th className="pl-1 pr-1 w-1">{'\xa0'}</th>
            </tr>
        </thead>
    );
};

const DocRow = (props) => {
    const { project, document, firstRow, headerRow, status, onClick } = props;
    const [deleteQuoteDoc, deleteQuoteDocResult] =
        useDeleteQuoteDocumentMutation();
    const firstCol =
        firstRow && headerRow ? (
            <ChevronButton
                open={status}
                onClick={() => onClick(!status)}
            />
        ) : !firstRow ? (
            <FontAwesomeIcon
                icon="file-solid fa-triangle-exclamation"
                style={{ color: '#d21414' }}
            />
        ) : null;

    if (!project) return null;

    const handleDeleteDoc = async (doc) => {
        try {
            await deleteQuoteDoc({
                id: project.id,
                docId: doc.id,
                seqId: doc.sequenceId,
            }).unwrap();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <tr
            className="border-b border-t border-black-20 text-base"
            key={document.id}
        >
            <td className="pl-1 pr-1">{firstCol}</td>
            <td className="pl-1 pr-4">
                <a
                    className="text-green-primary hover:underline hover:curosr-pointer"
                    href={`/api/documents/quote/${project.id}/doc/${document.docId}`}
                    download={document.originalDocName}
                >
                    {document.originalDocName}
                </a>
            </td>
            <td className="px-4">{document.revision}</td>
            <td className="px-4">
                {new Date(document.createdAt).toLocaleString('en-US')}
            </td>
            <td>
                <button
                    onClick={() => handleDeleteDoc(document)}
                >
                    <FontAwesomeIcon
                        className="mr-6"
                        icon="fa-solid fa-trash-can"
                    />
                </button>
            </td>
        </tr>
    );
};

const DocRows = (props) => {
    const { project, docs } = props;

    const [open, setOpen] = React.useState(false);

    if (docs.length === 1) {
        return (
            <DocRow
                document={docs[0]}
                project={project}
                firstRow={true}
                headerRow={false}
            />
        );
    }

    const orderedDocs = docs.sort((a, b) =>
        SortByDocCreatedDateDescending(a, b),
    );

    return (
        <React.Fragment>
            <DocRow
                key={orderedDocs[0].id}
                project={project}
                document={orderedDocs[0]}
                firstRow={true}
                headerRow={true}
                status={open}
                onClick={setOpen}
            />

            {open
                ? orderedDocs.map((doc, index) => {
                      if (index !== 0) {
                          return (
                              <DocRow
                                  key={doc.id}
                                  project={project}
                                  document={doc}
                                  firstRow={false}
                                  headerRow={false}
                              />
                          );
                      }
                      return null;
                  })
                : null}
        </React.Fragment>
    );
};

const DocumentBody = (props) => {
    const { project, documents, hasJobInProgress } = props;

    const groups = lodashGroupBy(documents ?? [], 'originalDocName');

    return (
        <tbody>
            { hasJobInProgress
                ? (
                    <tr
                        key={'Spinning'}
                    >
                        <td align="center" colSpan="5">
                            <Spinner color='green' />
                        </td>
                    </tr>)
                : null
            }
            {Object.entries(groups).map(([name, docs]) => (
                <DocRows
                    key={name}
                    project={project}
                    docs={docs}
                />
            ))}
        </tbody>
    );
};
