import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChevronDownIcon } from './icons';

export const HorizontalLine = () => (
    <hr className="self-stretch grow-0 my-4 mx-0 border border-black-5 bg-black-5" />
);

export const Modal = (props) => {
    const { show, fitContent, fullScreen, children, className: propsClassName } = props;

    if (!show) return null;

    const containerClassName = classNames(
        'relative transform overflow-hidden rounded-swegonSmall bg-white text-left shadow-xl transition-all',
        propsClassName,
        fitContent ? 'w-fit' : 'w-full',
        fullScreen ? 'max-w-full mx-4' : 'max-w-7xl my-8',
    );

    return (
        <div
            className="relative z-10"
            role="dialog"
            aria-modal="true"
        >
            {
            <div className="fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity"></div>
            }

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-center text-center items-center p-0">
                    <div className={containerClassName}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export const InitialsButton = ({ children, ...restProps }) => (
    <button
        className="w-8 h-8 border-none bg-blue-100 rounded-full justify-center flex cursor-pointer outline-none items-center"
        {...restProps}
    >
        <span className="text-xs uppercase text-white tracking-tight">
            {children}
        </span>
    </button>
);

export const Alert = ({ kind, msg, onClose }) => {
    const labels = {
        error: 'Error',
        info: 'Info',
        success: 'Success',
    };

    const colorClasses = {
        error: 'text-red-100 bg-red-5 border border-red-50',
        info: 'text-amber-100 bg-amber-5 border border-amber-50',
        success:
            'text-green-dark bg-green-secondary border border-green-primary',
    };

    return (
        <div
            className={classNames(
                'fixed bottom-4 left-4 p-4 rounded-lg shadow-swegon2 flex items-center',
                colorClasses[kind],
            )}
            role="alert"
        >
            <div className="flex items-center w-full">
                <FontAwesomeIcon icon="fa-solid fa-circle-info flex-shrink-0 w-5 h-5" />
                <span className="sr-only">{labels[kind]}</span>
                <div className="ml-3 flex-grow">{msg}</div>
                <button
                    type="button"
                    className="h-8 w-8 rounded-full hover:bg-gray-300"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </button>
            </div>
        </div>
    );
};

export const SelectField = (props) => {
    const { options, value, onChange } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    const onChangeWrapped = (option) => {
        if (!option?.disabled) {
            onChange(option);
        }
        setIsOpen(!isOpen);
    };

    return (
        <div className="swegon-like-select-wrapper">
            <div className="swegon-like-select-button-wrapper">
                <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {options.find((option) => option.value === value)?.name}
                </button>
                <div className="swegon-like-select-icon-wrapper">
                    <ChevronDownIcon />
                </div>
            </div>
            <div
                className={classNames('swegon-like-select-overlay-wrapper', {
                    hidden: !isOpen,
                })}
            >
                <div>
                    <div>
                        {options.map((option, idx) => (
                            <div
                                key={idx}
                                onClick={() => onChangeWrapped(option)}
                                className={classNames(
                                    'swegon-like-select-menu-item',
                                    {
                                        selected: option?.value === value,
                                        disabled: option?.disabled,
                                    },
                                )}
                            >
                                {option?.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const CheckboxField = (props) => {
    const { onChange, label, disabled, name, value, checked, labelClassName } =
        props;

    return (
        <div
            onClick={() => {
                if (!disabled) {
                    onChange();
                }
            }}
            className="swegon-like-checkbox-wrapper"
        >
            <input
                type="checkbox"
                className={name}
                value={value}
                checked={!!checked}
                disabled={disabled}
            />
            {labelClassName ? (
                <div
                    className={classNames(labelClassName, {
                        disabled: disabled,
                    })}
                >
                    {label}
                </div>
            ) : (
                <label className={classNames({ disabled: disabled })}>
                    {label}
                </label>
            )}
        </div>
    );
};

// const TableContext = React.createContext(null);

// const Table = (props) => {

//     const { bordered, fixed, centered, hovered, size, children, ...restProps } = props;

//     const tableClassName = classNames('w-full', { 'table-fixed': props.fixed });

//     return (
//         <TableContext.Provider value={{ bordered, fixed, centered, hovered }}>
//             <table className={tableClassName} {...restProps}>
//                 { children }
//             </table>
//         </TableContext.Provider>
//     );
// };

// const

// const TableContainer = ({children, ...rest}) => (
//     <div className="w-full border-collapse flex flex-col min-h-[60vh]" {...rest}>
//         { children }
//     </div>
// );

// const TableHeadContainer = ({children, ...rest}) => (
//     <div className="flex sticky z-[2] top-0" {...rest}>
//         { children }
//     </div>
// );

// const TableRowContainer = ({children, ...rest}) => (
//     <div className="flex h-12 hover:cursor-pointer hover:bg-green-secondary" {...rest}>
//         { children }
//     </div>
// );

// const TableDataContainer = ({children, ...rest}) => (
//     <div className="flex align-center" {...rest}>
//         { children }
//     </div>
// );

// export const TableRow = TableRowContainer;

// export const Table = (props) => {
//     return (
//         <TableContainer>
//             <TableHeadContainer>{ props.head }</TableHeadContainer>
//             <div>{ props.body }</div>
//         </TableContainer>
//     )
// };

// export const TableContainer = styled.div`
//     width: 100%;
//     border-collapse: collapse;
//     display: flex;
//     flex-direction: column;
//     min-height: 60vh;
// `

// export const TableHeadContainer = styled.div`
//     display: flex;
//     position: sticky;
//     top: 95px;
//     z-index: 2;
// `

// export const TableRowContainer = styled.div`
//     display: flex;
//     height: 50px;
//     &:hover {
//         cursor: pointer;
//         background-color: ${DSGT_GRAY_GREEN_0_5};
//     }

// `

// export const TableDataContainer = styled.div<Props>`
//     display: flex;
//     align-items: center;
//     ${({ width }) => width && `width: ${width}`}

// export const TableHeaderContainer = styled.div`
//     text-align: left;
//     background-color: white;
// `

// export const TableBodyContainer = styled.div``
