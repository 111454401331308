import React, { Fragment, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as storeExt from './store';
// import { useHubConnection } from './hubConnection';
import { Modal, QuickAlert, SelectField, CheckboxField } from './components';
import Creatable from 'react-select/creatable';
import ProductBrowser from './components/ProductBrowser';
import { Spinner } from '@swegon-core/ui-components';
// import { Button } from '@swegon-core/ui-components';
import ConfigProductBrowser from './components/ConfigProductBrowser';

const {
    useCreateProjectMutation,
    useCreateProjectTagsMutation,
    useUpdateProjectTagMutation,
    useDeleteProjectTagMutation,
    useDuplicateProjectTagMutation,
    useGetConfigQuery,
    useGetProjectQuery,
    useGetMeQuery,
    useSaveValidationMutation,
    useDeleteProjectMutation,
    mainActions,
} = storeExt;

export const SiteConditionsDemo = (props) => {
    const { show, onClose } = props;

    const [country, setCountry] = React.useState('United States');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [
        overrideCalculatedSeismicDesignCategory,
        setOverrideCalculatedSeismicDesignCategory,
    ] = React.useState(false);

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4 w-[600px]">
                <h1 className="pb-2 border-b border-black-10">
                    Site Conditions Configurator
                </h1>
                <div>
                    <h2>Project Information</h2>
                    <div className="mb-2 pb-2 border-b border-black-10">
                        <label className="block mb-2 text-sm font-medium">
                            Country
                        </label>
                        <SelectField
                            value={country}
                            onChange={(option) => setCountry(option.value)}
                            options={[
                                { value: '', name: '' },
                                {
                                    value: 'United States',
                                    name: 'United States',
                                },
                            ]}
                        />
                    </div>
                    <div className="mb-2 pb-2 border-b border-black-10">
                        <label className="block mb-2 text-sm font-medium">
                            City
                        </label>
                        <div className="swegon-ui-text-input-wrapper">
                            <input
                                type="text"
                                name="city"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                            />
                        </div>
                    </div>
                    <div className="mb-2 pb-2 border-b border-black-10">
                        <label className="block mb-2 text-sm font-medium">
                            State
                        </label>
                        <div className="swegon-ui-text-input-wrapper">
                            <input
                                type="text"
                                name="state"
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                            />
                        </div>
                    </div>
                    <h2 className="mt-4">Seismic Load Parameters</h2>
                    <div className="mb-2 pb-2 border-b border-black-10">
                        <CheckboxField
                            onChange={() =>
                                setOverrideCalculatedSeismicDesignCategory(
                                    !overrideCalculatedSeismicDesignCategory,
                                )
                            }
                            active={overrideCalculatedSeismicDesignCategory}
                            label="Override Calculated Seismic Design Category"
                        />
                    </div>
                </div>
                <div className="mt-4 bg-gray-50 flex justify-end">
                    <button
                        className="swegon-like-button outlined mr-4 !px-4 amber"
                        type="button"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const NewProjectModal = (props) => {
    const { show, onClose } = props;

    const navigate = useNavigate();

    const [createProject, _createProjectResult] = useCreateProjectMutation();

    const initialValues = { name: '' };

    const handleSubmit = async (values, formikHelpers) => {
        const res = await createProject(values).unwrap();
        formikHelpers.setSubmitting(false);
        onClose();
        navigate(`/projects/${res.id}`);
    };

    const handleValidate = (values) => {
        let errors = {};
        if (!(values.name?.length > 0)) {
            errors['name'] = 'Name cannot be blank';
        }
        return errors;
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4">
                <h1>New Project</h1>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validate={handleValidate}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                {Object.keys(formik.errors ?? {}).map(
                                    (k) =>
                                        formik.touched?.[k] && (
                                            <QuickAlert
                                                kind="error"
                                                msg={formik.errors?.[k]}
                                            />
                                        ),
                                )}
                                <div className="mb-4 w-96">
                                    <div className="mb-4">
                                        <label
                                            className="block mb-2 text-sm font-medium"
                                            htmlFor="name"
                                        >
                                            Name
                                        </label>
                                        <div className="swegon-ui-text-input-wrapper">
                                            <input
                                                type="text"
                                                name="name"
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        className="swegon-like-button outlined mr-4 !px-4 amber"
                                        type="button"
                                        onClick={onClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="swegon-like-button contained !px-4"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

export const DeleteProjectModal = (props) => {
    const { project, onClose } = props;
    const [deleteProject] = useDeleteProjectMutation();
    const dispatch = useDispatch();

    const handleDelete = async () => {
        onClose();

        dispatch(mainActions.showWaitingModal({
            isSimple: true,
            msg: 'Deleting Project...Please wait...',
        }),);
        try {
            await deleteProject({ id: project.id });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }        
    };

    let showModal = false;
    let projectName = '';
    if (project) {
        showModal = true;

        const { name } = project;
        projectName = name;
    }

    return (
        <>
        <Modal
            fitContent
            show={showModal}
        >
            <div className="sm:container p-4">
                <h1>Delete this Project?</h1>
                <div className="mb-4">
                    <div className="flex justify-between gap-4 mb-2">
                        <div>
                            <div className="swegon-ui-text-input-wrapper">
                                <div>{projectName}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        type="button"
                        className="swegon-like-button outlined mr-4 !px-4 amber"
                    >
                        Cancel
                    </button>
                    <button
                        className="swegon-like-button contained red"
                        type="submit"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Modal>
        <WaitingModal/>
        </>
    );
};

const setBusinessSystemAttribute = (
    product,
    fieldName,
    fieldValueFieldName,
    fieldValue,
) => {
    const config = product?.configurators?.find((x) => {
        return x.name === 'Business System Attributes';
    });
    const field = config?.fields.find((x) => {
        return x.name === fieldName;
    });
    if (field) {
        field[fieldValueFieldName] = fieldValue;
    }
};

const setNestedConfigAttribute = (
    product,
    nestedConfigId,
    fieldName,
    fieldValueFieldName,
    fieldValue,
) => {
    const config = product?.configurators?.find((x) => {
        return x.idProduct === nestedConfigId;
    });
    const field = config?.fields.find((x) => {
        return x.name === fieldName;
    });
    if (field) {
        field[fieldValueFieldName] = fieldValue;
    }
};

const normalizeSrcAttributes = (srcAttributesArray) => {
    return srcAttributesArray.map((x) => {
        const valueFieldName = Object.keys(x)?.find((key) => {
            return !(key === 'id' || key === 'name');
        });
        return {
            name: x.name,
            valueFieldName: valueFieldName,
            value: x[valueFieldName],
        };
    });
};

const setSeismicWindAttribute = (
    product,
    nestedConfigId,
    fieldName,
    fieldValueFieldName,
    fieldValue,
) =>
    setNestedConfigAttribute(
        product,
        nestedConfigId,
        fieldName,
        fieldValueFieldName,
        fieldValue,
    );

const CpqModalInner = (props) => {
    const {
        configuratorId,
        onSubmit,
        onClose,
        onConfiguratorLoaded,
        projectInfo,
        projectSiteInfoJson,
        viewerClassNames,
        fullScreen,
    } = props;

    const { data: config } = useGetConfigQuery();
    const { data: currentUser, isError: getMeIsError } = useGetMeQuery();
    const viewerElementReference = React.useRef(null);
    const [embedConfigurator, setEmbedConfigurator] = React.useState(null);
    const [configuredProductDefaults, setConfiguredProductDefaults] =
        React.useState(null);

    const currency =
        currentUser?.settings?.find((s) => s.key === 'currency')?.value ??
        'USD';
    const kbmaxUrl = config?.kbmaxUrl;

    React.useEffect(() => {
        if (
            !viewerElementReference.current ||
            !kbmaxUrl ||
            !configuratorId ||
            !currency
        ) {
            return;
        }

        const configurator = new window.kbmax.ConfiguratorEmbed({
            kbmaxUrl,
            elementId: 'viewer',
            configuratorId: configuratorId,
            showHeader: false,
            showDrawer: false,
            showSubmitButton: true,
            showMove: false,
            loadStyle: 'none',
            idTheme: 15,
            currency,
        });

        window.__KBMaxConfig__ = configurator;

        setEmbedConfigurator(configurator);
    }, [configuratorId, currency, kbmaxUrl]);

    React.useEffect(() => {
        if (onConfiguratorLoaded && embedConfigurator) {
            onConfiguratorLoaded(embedConfigurator);
        }
    }, [onConfiguratorLoaded, embedConfigurator]);

    React.useEffect(() => {
        if (!embedConfigurator || !configuredProductDefaults) {
            return;
        }

        embedConfigurator.setConfiguredProduct(configuredProductDefaults);
    }, [embedConfigurator, configuredProductDefaults]);

    const getCurrentConfiguredProduct = React.useCallback(() => {
        if (!embedConfigurator) {
            return null;
        }

        return new Promise((pReturnValue) => {
            embedConfigurator.getConfiguredProduct((p) => {
                pReturnValue(p);
            });
        });
    }, [embedConfigurator]);

    React.useEffect(() => {
        if (!projectInfo) {
            return;
        }

        (async () => {
            const product = await getCurrentConfiguredProduct();

            setBusinessSystemAttribute(
                product,
                'fProjectName',
                'stringValue',
                projectInfo.name,
            );
            setBusinessSystemAttribute(
                product,
                'fQuoteName',
                'stringValue',
                projectInfo.name,
            );
            setBusinessSystemAttribute(
                product,
                'fCurrency',
                'stringValue',
                projectInfo.currency,
            );

            if (
                projectSiteInfoJson &&
                product &&
                (product?.idProduct === config?.configurators.RoundTank ||
                    product?.idProduct ===
                        config?.configurators.IsolatedInertiaBase ||
                    product?.idProduct === config?.configurators.PipeRiser ||
                    product?.idProduct === config?.configurators.CTB ||
                    product?.idProduct === config?.configurators.ARTR ||
                    product?.idProduct === config?.configurators.IsolatorSelection)
            ) {
                // && product?.idProduct === 588)
                const siteInfo = JSON.parse(projectSiteInfoJson);
                const srcAttributes = normalizeSrcAttributes(siteInfo?.fields);
                for (let idx = 0; idx < srcAttributes.length; idx++) {
                    setSeismicWindAttribute(
                        product,
                        config?.configurators.SeismicAndWind,
                        srcAttributes[idx].name,
                        srcAttributes[idx].valueFieldName,
                        srcAttributes[idx].value,
                    );
                }
            }

            return product;
        })().then(setConfiguredProductDefaults);
    }, [
        getCurrentConfiguredProduct,
        projectInfo,
        projectSiteInfoJson,
        config?.configurators.RoundTank,
        config?.configurators.IsolatedInertiaBase,
        config?.configurators.PipeRiser,
        config?.configurators.CTB,
        config?.configurators.ARTR,
        config?.configurators.IsolatorSelection,
        config?.configurators.SeismicAndWind,
    ]);

    const handleSubmit = async () => {
        const product = await getCurrentConfiguredProduct();
        onSubmit(product);
        onClose();
    };

    const viewHeightClassName = fullScreen
        ? 'h-[calc(100vh-150px)]'
        : 'h-[calc(100vh-216px)]'

    return (
        <>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div
                    className={classNames(
                        'mb-1 w-full',
                        viewHeightClassName,
                        viewerClassNames,
                    )}
                    ref={viewerElementReference}
                    id="viewer"
                />
            </div>
            <div className="px-4 py-3 sm:flex sm:px-6 justify-end">
                <button
                    onClick={onClose}
                    type="button"
                    className="swegon-like-button outlined mr-4 !px-4 amber"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    type="button"
                    className="swegon-like-button contained !px-4"
                >
                    Save
                </button>
            </div>
        </>
    );
};

export const CpqModal = (props) => {
    const { data: config } = useGetConfigQuery();
    const { show, fullScreen, configuratorId, viewerClassNames, ...restProps } = props;

    React.useEffect(() => {
        if (!config?.kbmaxUrl) return;

        const addScript = document.createElement('script');
        addScript.setAttribute('src', `${config.kbmaxUrl}/embed.min.js`);
        document.body.append(addScript);

        return () => {
            return addScript.remove();
        };
    }, [show, config?.kbmaxUrl]);

    return (
        <Modal
            fullScreen={fullScreen}
            show={show}
        >
            <CpqModalInner
                viewerClassNames={viewerClassNames}
                configuratorId={configuratorId}
                fullScreen={fullScreen}
                {...restProps}
            />
        </Modal>
    );
};

export const NewTagModal = (props) => {
    const { data: config } = useGetConfigQuery();

    const { show, onClose, projectId, tags } = props;

    const [createProjectTags, createProjectTagsResult] =
        useCreateProjectTagsMutation();

    const defaultTagValues = {
        value: '',
        room: '',
        equipmentType: '',
    };

    const handleSubmit = async (values, formikHelpers) => {
        createProjectTags({ id: projectId, tags: values.tags });
        // const res = await dispatch(hubConn.upsertProjectTags({ form: { projectId: projectId, ...values } }));
        formikHelpers.setSubmitting(false);
        onClose();
    };

    const handleValidate = (values) => {
        let errors = {};
        // if (!(values.value?.length > 0)) errors['value'] = 'Tag name cannot be blank';
        // if (!(values.room?.length > 0)) errors['room'] = 'Room cannot be blank';
        // if (!(values.equipmentType?.length > 0)) errors['equipmentType'] = 'Equipment cannot be blank';
        return errors;
    };

    const equipmentList = config?.options.equipmentList ?? [];
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="sm:container p-4">
                <h1>Tag</h1>
                <div>
                    <Formik
                        initialValues={{ tags: [defaultTagValues] }}
                        validate={handleValidate}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                {Object.keys(formik.errors ?? {}).map(
                                    (k) =>
                                        formik.touched?.[k] && (
                                            <QuickAlert
                                                key={k}
                                                kind="error"
                                                msg={formik.errors?.[k]}
                                            />
                                        ),
                                )}
                                <FieldArray name="tags">
                                    {(tagsHelpers) => (
                                        <div className="mb-4">
                                            {formik.values.tags.map(
                                                (row, idx) => (
                                                    <div
                                                        key={idx}
                                                        className="flex justify-between gap-4 mb-2"
                                                    >
                                                        <div>
                                                            <label
                                                                className={classNames(
                                                                    'block mb-2 text-sm font-medium',
                                                                    {
                                                                        hidden:
                                                                            idx !==
                                                                            0,
                                                                    },
                                                                )}
                                                                htmlFor={`tags[${idx}].value`}
                                                            >
                                                                Tag
                                                            </label>
                                                            <Creatable
                                                                classNames={{
                                                                    control: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            'flex items-center w-full min-w-[200px] !bg-black-5  border border-black-10 !hover:border-black-20 !rounded-none !shadow-none',

                                                                            state.isFocused &&
                                                                                '!border-green-dark',
                                                                        ),
                                                                    option: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            state.isSelected &&
                                                                                'bg-green-primary text-white',
                                                                            'hover:bg-green-primary hover:text-white over:text-white',
                                                                        ),
                                                                    menu: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            'shadow-swegon2 border border-black-20 !z-50',
                                                                        ),
                                                                    menuPortal:
                                                                        (
                                                                            state,
                                                                        ) =>
                                                                            '!z-50',
                                                                }}
                                                                menuPortalTarget={
                                                                    document.body
                                                                }
                                                                components={{
                                                                    DropdownIndicator:
                                                                        null,
                                                                }}
                                                                hideSelectedOptions
                                                                options={[
                                                                    ...new Set(
                                                                        tags
                                                                            .map(
                                                                                (
                                                                                    tag,
                                                                                ) =>
                                                                                    tag.value,
                                                                            )
                                                                            .sort(),
                                                                    ),
                                                                ].map(
                                                                    (
                                                                        value,
                                                                    ) => ({
                                                                        value,
                                                                        label: value,
                                                                    }),
                                                                )}
                                                                onChange={(
                                                                    selectedOption,
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        `tags[${idx}].value`,
                                                                        selectedOption
                                                                            ? selectedOption.value
                                                                            : '',
                                                                    );
                                                                }}
                                                                onBlur={(
                                                                    event,
                                                                ) => {
                                                                    const inputValue =
                                                                        event
                                                                            .target
                                                                            .value;
                                                                    if (
                                                                        inputValue.trim() !==
                                                                        ''
                                                                    ) {
                                                                        formik.setFieldValue(
                                                                            `tags[${idx}].value`,
                                                                            inputValue,
                                                                        );
                                                                    }
                                                                }}
                                                                value={{
                                                                    value: row.value,
                                                                    label: row.value,
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label
                                                                className={classNames(
                                                                    'block mb-2 text-sm font-medium',
                                                                    {
                                                                        hidden:
                                                                            idx !==
                                                                            0,
                                                                    },
                                                                )}
                                                                htmlFor={`tags[${idx}].equipmentType`}
                                                            >
                                                                Equipment Type
                                                            </label>

                                                            <Creatable
                                                                classNames={{
                                                                    control: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            'flex items-center w-full min-w-[200px] !bg-black-5  border border-black-10 !hover:border-black-20 !rounded-none !shadow-none',

                                                                            state.isFocused &&
                                                                                '!border-green-dark',
                                                                        ),
                                                                    option: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            state.isSelected &&
                                                                                'bg-green-primary text-white',
                                                                            'hover:bg-green-primary hover:text-white over:text-white',
                                                                        ),
                                                                    menu: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            'shadow-swegon2 border border-black-20 !z-50',
                                                                        ),
                                                                    menuPortal:
                                                                        (
                                                                            state,
                                                                        ) =>
                                                                            '!z-50',
                                                                }}
                                                                menuPortalTarget={
                                                                    document.body
                                                                }
                                                                components={{
                                                                    DropdownIndicator:
                                                                        null,
                                                                }}
                                                                hideSelectedOptions
                                                                options={equipmentList.map(
                                                                    (
                                                                        value,
                                                                    ) => ({
                                                                        value,
                                                                        label: value,
                                                                    }),
                                                                )}
                                                                onChange={(
                                                                    selectedOption,
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        `tags[${idx}].equipmentType`,
                                                                        selectedOption
                                                                            ? selectedOption.value
                                                                            : '',
                                                                    );
                                                                }}
                                                                onBlur={(
                                                                    event,
                                                                ) => {
                                                                    const inputValue =
                                                                        event
                                                                            .target
                                                                            .value;
                                                                    if (
                                                                        inputValue.trim() !==
                                                                        ''
                                                                    ) {
                                                                        formik.setFieldValue(
                                                                            `tags[${idx}].equipmentType`,
                                                                            inputValue,
                                                                        );
                                                                    }
                                                                }}
                                                                value={{
                                                                    value: row.equipmentType,
                                                                    label: row.equipmentType,
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label
                                                                className={classNames(
                                                                    'block mb-2 text-sm font-medium',
                                                                    {
                                                                        hidden:
                                                                            idx !==
                                                                            0,
                                                                    },
                                                                )}
                                                                htmlFor={`tags[${idx}].room`}
                                                            >
                                                                Room
                                                            </label>
                                                            <Creatable
                                                                classNames={{
                                                                    control: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            'flex items-center w-full min-w-[200px] !bg-black-5  border border-black-10 !hover:border-black-20 !rounded-none !shadow-none',

                                                                            state.isFocused &&
                                                                                '!border-green-dark',
                                                                        ),
                                                                    option: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            state.isSelected &&
                                                                                'bg-green-primary text-white',
                                                                            'hover:bg-green-primary hover:text-white over:text-white',
                                                                        ),
                                                                    menu: (
                                                                        state,
                                                                    ) =>
                                                                        classNames(
                                                                            'shadow-swegon2 border border-black-20 !z-50',
                                                                        ),
                                                                    menuPortal:
                                                                        (
                                                                            state,
                                                                        ) =>
                                                                            '!z-50',
                                                                }}
                                                                menuPortalTarget={
                                                                    document.body
                                                                }
                                                                components={{
                                                                    DropdownIndicator:
                                                                        null,
                                                                }}
                                                                hideSelectedOptions
                                                                options={[
                                                                    ...new Set(
                                                                        tags
                                                                            .map(
                                                                                (
                                                                                    tag,
                                                                                ) =>
                                                                                    tag.room,
                                                                            )
                                                                            .sort(),
                                                                    ),
                                                                ].map(
                                                                    (
                                                                        value,
                                                                    ) => ({
                                                                        value,
                                                                        label: value,
                                                                    }),
                                                                )}
                                                                onChange={(
                                                                    selectedOption,
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        `tags[${idx}].room`,
                                                                        selectedOption
                                                                            ? selectedOption.value
                                                                            : '',
                                                                    );
                                                                }}
                                                                onBlur={(
                                                                    event,
                                                                ) => {
                                                                    const inputValue =
                                                                        event
                                                                            .target
                                                                            .value;
                                                                    if (
                                                                        inputValue.trim() !==
                                                                        ''
                                                                    ) {
                                                                        formik.setFieldValue(
                                                                            `tags[${idx}].room`,
                                                                            inputValue,
                                                                        );
                                                                    }
                                                                }}
                                                                value={{
                                                                    value: row.room,
                                                                    label: row.room,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                            <div>
                                                <button
                                                    className="swegon-like-button outlined"
                                                    type="button"
                                                    onClick={() =>
                                                        tagsHelpers.push(
                                                            defaultTagValues,
                                                        )
                                                    }
                                                >
                                                    Add tag
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="flex justify-end">
                                    <button
                                        onClick={onClose}
                                        type="button"
                                        className="swegon-like-button outlined mr-4 !px-4 amber"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="swegon-like-button contained"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

export const EditTagModal = (props) => {
    const { show, onClose, projectId, tag } = props;
    const [updateProjectTag] = useUpdateProjectTagMutation();

    const handleSubmit = async (values, formikHelpers) => {
        updateProjectTag({ id: projectId, tag: values });
        // const res = await dispatch(hubConn.upsertProjectTags({ form: { projectId: projectId, ...values } }));
        formikHelpers.setSubmitting(false);
        onClose();
    };

    const handleValidate = (values) => {
        let errors = {};
        // if (!(values.value?.length > 0)) errors['value'] = 'Tag name cannot be blank';
        // if (!(values.room?.length > 0)) errors['room'] = 'Room cannot be blank';
        // if (!(values.equipmentType?.length > 0)) errors['equipmentType'] = 'Equipment cannot be blank';
        return errors;
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="sm:container p-4">
                <h1>Tag</h1>
                <div>
                    <Formik
                        initialValues={tag}
                        validate={handleValidate}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                {Object.keys(formik.errors ?? {}).map(
                                    (k) =>
                                        formik.touched?.[k] && (
                                            <QuickAlert
                                                key={k}
                                                kind="error"
                                                msg={formik.errors?.[k]}
                                            />
                                        ),
                                )}
                                <div className="mb-4">
                                    <div className="flex justify-between gap-4 mb-2">
                                        <div>
                                            <label
                                                className={classNames(
                                                    'block mb-2 text-sm font-medium',
                                                )}
                                                htmlFor={'value'}
                                            >
                                                Tag
                                            </label>
                                            <div className="swegon-ui-text-input-wrapper">
                                                <input
                                                    type="text"
                                                    name={'value'}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={formik.values.value}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                className={classNames(
                                                    'block mb-2 text-sm font-medium',
                                                )}
                                                htmlFor={'equipmentType'}
                                            >
                                                Equipment Type
                                            </label>
                                            <div className="swegon-ui-text-input-wrapper">
                                                <input
                                                    type="text"
                                                    name={'equipmentType'}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={
                                                        formik.values
                                                            .equipmentType
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                className={classNames(
                                                    'block mb-2 text-sm font-medium',
                                                )}
                                                htmlFor={'value'}
                                            >
                                                Room
                                            </label>
                                            <div className="swegon-ui-text-input-wrapper">
                                                <input
                                                    type="text"
                                                    name={'room'}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={formik.values.room}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        onClick={onClose}
                                        type="button"
                                        className="swegon-like-button outlined mr-4 !px-4 amber"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="swegon-like-button contained"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        Update
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

export const DuplicateTagModal = (props) => {
    const {
        show,
        onClose,
        projectId,
        tag,
    } = props;
    const [duplicateProjectTag, duplicaProjectTagResult] =
        useDuplicateProjectTagMutation();
    const dispatch = useDispatch();

    const defaultTagValues = {
        value: tag.value,
        room: tag.room,
        equipmentType: tag.equipmentType,
    };

    const handleSubmit = async (values, formikHelpers) => {
        dispatch(mainActions.showWaitingModal({
            isSimple: true,
            msg: 'Duplicating tag and its product(s)....',
        }),);
        formikHelpers.setSubmitting(false);
        onClose();
        try {
            await duplicateProjectTag({
                id: projectId,
                tagId: tag.id,
                tags: values.tags[0],
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            // const res = await dispatch(hubConn.upsertProjectTags({ form: { projectId: projectId, ...values } }));
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const handleValidate = (values) => {
        let errors = {};
        // if (!(values.value?.length > 0)) errors['value'] = 'Tag name cannot be blank';
        // if (!(values.room?.length > 0)) errors['room'] = 'Room cannot be blank';
        // if (!(values.equipmentType?.length > 0)) errors['equipmentType'] = 'Equipment cannot be blank';
        return errors;
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="sm:container p-4">
                <h1>Tag</h1>
                <div>
                    <Formik
                        initialValues={{ tags: [defaultTagValues] }}
                        validate={handleValidate}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                {Object.keys(formik.errors ?? {}).map(
                                    (k) =>
                                        formik.touched?.[k] && (
                                            <QuickAlert
                                                key={k}
                                                kind="error"
                                                msg={formik.errors?.[k]}
                                            />
                                        ),
                                )}
                                <FieldArray name="tags">
                                    {(tagsHelpers) => (
                                        <div className="mb-4">
                                            {formik.values.tags.map(
                                                (row, idx) => (
                                                    <div
                                                        key={idx}
                                                        className="flex justify-between gap-4 mb-2"
                                                    >
                                                        <div>
                                                            <label
                                                                className={classNames(
                                                                    'block mb-2 text-sm font-medium',
                                                                    {
                                                                        hidden:
                                                                            idx !==
                                                                            0,
                                                                    },
                                                                )}
                                                                htmlFor={`tags.${idx}.value`}
                                                            >
                                                                Tag
                                                            </label>
                                                            <div className="swegon-ui-text-input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    name={`tags.${idx}.value`}
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    value={
                                                                        row.value
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label
                                                                className={classNames(
                                                                    'block mb-2 text-sm font-medium',
                                                                    {
                                                                        hidden:
                                                                            idx !==
                                                                            0,
                                                                    },
                                                                )}
                                                                htmlFor={`tags.${idx}.equipmentType`}
                                                            >
                                                                Equipment Type
                                                            </label>
                                                            <div className="swegon-ui-text-input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    name={`tags.${idx}.equipmentType`}
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    value={
                                                                        row.equipmentType
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label
                                                                className={classNames(
                                                                    'block mb-2 text-sm font-medium',
                                                                    {
                                                                        hidden:
                                                                            idx !==
                                                                            0,
                                                                    },
                                                                )}
                                                                htmlFor={`tags.${idx}.room`}
                                                            >
                                                                Room
                                                            </label>
                                                            <div className="swegon-ui-text-input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    name={`tags.${idx}.room`}
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    value={
                                                                        row.room
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                            {/*<div>
                                                <button
                                                    className="swegon-like-button outlined"
                                                    type="button"
                                                    onClick={() =>
                                                        tagsHelpers.push(
                                                            defaultTagValues,
                                                        )
                                                    }
                                                >
                                                    Add tag
                                                </button>
                                                </div>*/}
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="flex justify-end">
                                    <button
                                        onClick={onClose}
                                        type="button"
                                        className="swegon-like-button outlined mr-4 !px-4 amber"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="swegon-like-button contained"
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

export const DeleteTagModal = (props) => {
    const { show, onClose, projectId, tag } = props;
    const [deleteProjectTag] = useDeleteProjectTagMutation();

    const handleDelete = () => {
        deleteProjectTag({ id: projectId, tag: tag });
        onClose();
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="sm:container p-4">
                <h1>Delete this Tag?</h1>
                <div className="mb-4">
                    <div className="flex justify-between gap-4 mb-2">
                        <div>
                            <label
                                className={classNames(
                                    'block mb-2 text-sm font-medium',
                                )}
                            >
                                Tag
                            </label>
                            <div className="swegon-ui-text-input-wrapper">
                                <div>{tag.value}</div>
                            </div>
                        </div>
                        <div>
                            <label
                                className={classNames(
                                    'block mb-2 text-sm font-medium',
                                )}
                            >
                                Equipment Type
                            </label>
                            <div className="swegon-ui-text-input-wrapper">
                                <div>{tag.equipmentType}</div>
                            </div>
                        </div>
                        <div>
                            <label
                                className={classNames(
                                    'block mb-2 text-sm font-medium',
                                )}
                            >
                                Room
                            </label>
                            <div className="swegon-ui-text-input-wrapper">
                                <div>{tag.room}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        type="button"
                        className="swegon-like-button outlined mr-4 !px-4 amber"
                    >
                        Cancel
                    </button>
                    <button
                        className="swegon-like-button contained red"
                        type="submit"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const AddProductModal = (props) => {
    const { show, onClose, projectId, tag } = props;

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="bg-black-5">
                <div className="divide-y-2 divide-solid">
                    <div className="sm:container pl-4 pr-4 pt-4">
                        <h1>Product Catalog</h1>
                        <ProductBrowser
                            show={show}
                            tag={tag}
                            projectId={projectId}
                        />
                    </div>
                    <div className="flex justify-end p-4">
                        <button
                            onClick={onClose}
                            type="button"
                            className="swegon-like-button contained"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const AddConfigProductModal = (props) => {
    const { show, onClose, projectId, tag, saveHandler } = props;

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="bg-black-5">
                <div className="divide-y-2 divide-solid">
                    <div className="sm:container pl-4 pr-4 pt-4">
                        <h1>Configured Product Catalog</h1>
                        <ConfigProductBrowser
                            show={show}
                            tag={tag}
                            projectId={projectId}
                            saveHandler={saveHandler}
                            onClose={onClose}
                        />
                    </div>
                    <div className="flex justify-end p-4">
                        <button
                            onClick={onClose}
                            type="button"
                            className="swegon-like-button contained"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const ExportDocModal = (props) => {
    const { show, onClose, onExport, needExport, children } = props;
    const exportBtnCSS = classNames('swegon-like-button',
        needExport ? 'contained' : 'outlined',
        '!px-4',
        needExport ? '' : 'text-gray-50'
    );

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4 min-w-min ml-4 mr-4 mt-2">
                {children}
                <div className="px-4 py-3 sm:flex sm:px-6 justify-end">
                    <button
                        onClick={onClose}
                        type="button"
                        className="swegon-like-button outlined mr-4 !px-4 amber"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onExport}
                        type="button"
                        className={exportBtnCSS}
                        disabled={!needExport}
                    >
                        Export
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const ValidateWarningModal = (props) => {
    const { show, onClose, onValidate } = props;

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4 min-w-min ml-4 mr-4 mt-2">
                <h1>Project have item(s) need validation</h1>
                <div className="mt-2 mb-6 px-2">
                    There are items that need validation after your change in
                    site info
                </div>
                <div className="px-4 py-3 sm:flex sm:px-6 justify-end">
                    <button
                        onClick={onClose}
                        type="button"
                        className="swegon-like-button outlined mr-4 !px-4 amber"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onValidate}
                        type="button"
                        className="swegon-like-button contained !px-4"
                    >
                        Validate
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const ValidatingModal = (props) => {
    const { show, itemsToValidate, onValidateItem, itemsValidated } = props;
    const [startValidation, setStartValidation] = useState(false);

    React.useEffect(() => {
        setStartValidation(false);
    }, [itemsToValidate]);

    const handleValidateItems = () => {
        setStartValidation(true);
        onValidateItem();
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4 min-w-min ml-4 mr-4 mt-2">
                <div className="mt-2 mb-2 px-2">
                    <div>
                        {startValidation
                            ? `Validating ${itemsValidated} of ${itemsToValidate.length}`
                            : `${itemsToValidate.length} items waiting to be validate`}
                    </div>
                </div>
                <div className="px-4 sm:flex mb-4 justify-center">
                    {startValidation && <Spinner color="green" />}
                </div>
                <div className="px-4 py-3 sm:flex sm:px-6 justify-end">
                    {/*
                    <button
                        onClick={onClose}
                        type="button"
                        className="swegon-like-button outlined mr-4 !px-4 amber"
                    >
                        Cancel
                    </button>
                    */}
                    <button
                        onClick={handleValidateItems}
                        type="button"
                        className="swegon-like-button contained !px-4"
                        disabled={startValidation}
                    >
                        Validate
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const SaveValidationModal = (props) => {
    const { show, /*onClose,*/ data, projectId, onClose } = props;
    const [startSaving, setStartSaving] = useState(false);
    const [saveValdiations, saveValdiationsResult] =
        useSaveValidationMutation();

    const handleSubmit = () => {
        setStartSaving(true);
        saveValdiations({ id: projectId, ...data });
        setStartSaving(false);
        onClose();
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4 min-w-min ml-4 mr-4 mt-2">
                <div className="mt-2 mb-2 px-2">
                    {!startSaving
                        ? 'Ready to save validation results'
                        : 'Saving validation results ...'}
                </div>
                <div className="px-4 sm:flex mb-4 justify-center">
                    {startSaving && <Spinner color="green" />}
                </div>
                <div className="px-4 py-3 sm:flex sm:px-6 justify-end">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="swegon-like-button contained !px-4"
                        disabled={startSaving}
                    >
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const WaitingModal = (props) => {
    const { show, msg } = useSelector(
        storeExt.mainSelectors.getWaitingModalData,
    );
    // const show  = false;
    // const msg = null;
    const finalMessage = (msg) => {
        if (msg?.isSimple && msg?.msg) {
            return msg.msg;
        }
        if (!msg?.isSimple && msg?.msg) {
            const msgArray = msg.msg.split('\n');
            return msgArray.map((item, idx) => <div key={idx}>{item}</div>);
        }
        return 'Please wait ...';
    };

    return (
        <Modal
            fitContent
            show={show}
        >
            <div className="p-4 min-w-min ml-4 mr-4 mt-2">
                <div className="mt-2 mb-2 px-2">{finalMessage(msg)}</div>
                <div className="px-4 sm:flex mb-8 justify-center">
                    <Spinner color="green" />
                </div>
            </div>
        </Modal>
    );
};
